import React, { FC } from "react";
import { Card } from "@components/ui/Card";

type Props = {
  modalStyle: boolean;
};

const ReviewCardSkeleton: FC<Props> = ({ modalStyle }) => {
  return (
    <Card className="w-full">
      <Card.Container className="pt-0">
        {/* User */}
        <div
          className={`flex ${modalStyle ? "flex-col" : "flex-col lg:flex-row"} items-start mb-4 border-b pb-4 w-full`}
        >
          <div
            className={`flex  ${modalStyle ? "w-full" : "w-full lg:w-auto"} mt-4`}
          >
            <div className="flex items-center">
              <div>
                <div className="w-8 h-8 bg-gray-100 rounded-full mr-2" />
              </div>
              <div>
                <div className="w-32 h-3 bg-gray-100 rounded-full" />
                <div className="w-32 h-3 bg-gray-100 rounded-full mt-2" />
              </div>
            </div>
            {/* mobile only */}
            <div
              className={`${modalStyle ? "flex" : "flex lg:hidden"} ml-auto`}
            >
              <div className="text-sm text-gray-500">
                <div className="w-32 h-4 bg-gray-100 rounded-full" />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full pt-2">
            {/* Tour details */}
            <div className="flex flex-col">
              <a className={`flex flex-col w-full rounded-xl px-4 py-2`}>
                <div className="flex">
                  <p
                    className={`text-lg font-semibold text-blue-700 truncate-text whitespace-normal rounded-t-xl w-full`}
                  >
                    <div className="w-full h-4 bg-gray-100 rounded-full" />
                  </p>
                  <div
                    className={`hidden whitespace-nowrap  ${modalStyle ? "flex" : "lg:flex"} text-sm text-gray-500 ml-auto`}
                  >
                    <div className="w-32 h-4 bg-gray-100 rounded-full ml-4" />
                  </div>
                </div>

                <Card.Tags className="pt-2">
                  <Card.Tag className="whitespace-nowrap text-gray-100 bg-gray-100">
                    xxxxxxxxxxxxx
                  </Card.Tag>
                  <Card.Tag className="text-gray-100 bg-gray-100">
                    xxxxxxxxxxxxx
                  </Card.Tag>
                </Card.Tags>
              </a>
            </div>
          </div>
        </div>

        {/* Title */}
        <p className="text-lg font-semibold text-gray-800 mb-2 bg-gray-100 w-64 h-4 rounded-full"></p>

        {/* Message */}
        <p className="text-gray-600 text-sm leading-5 mb-4">
          <div className="w-full bg-gray-100 h-3" />
          <div className="w-full bg-gray-100 h-3 mt-2" />
          <div className="w-full bg-gray-100 h-3 mt-2" />
        </p>

        <div className="flex flex-row flex-wrap gap-2 my-4">
          <div className="w-16 h-16 rounded-xl bg-gray-100" />
          <div className="w-16 h-16 rounded-xl bg-gray-100" />
          <div className="w-16 h-16 rounded-xl bg-gray-100" />
          <div className="w-16 h-16 rounded-xl bg-gray-100" />
        </div>

        {/* Reply */}
        <div className="bg-gray-100 p-4 rounded">
          <div className="flex flex-row">
            <div className="w-16 h-16 rounded-full mr-2 bg-gray-200" />
            <div className="flex flex-col w-full">
              <div className="flex flex-row mb-2 items-center">
                <p className="text-lg font-semibold text-secondary bg-gray-200 w-32 h-4 rounded-full" />
                <button
                  onClick={() => {}}
                  className="text-xs font-bold underline text-blue-700 ml-2 py-1 w-16 h-4 rounded-full bg-gray-200"
                />
              </div>
              <p className="flex flex-col text-sm w-full">
                <div className="flex w-full h-2 bg-gray-200 rounded-full" />
                <div className="flex w-full h-2 bg-gray-200 rounded-full mt-2" />
                <div className="flex w-full h-2 bg-gray-200 rounded-full mt-2" />
              </p>
            </div>
          </div>
        </div>
      </Card.Container>
    </Card>
  );
};

export default ReviewCardSkeleton;
