import React, { ReactNode } from "react";
import { FC } from "react";
import { ReviewCardFragment, ReviewsQueryVariables } from "@graphql/types";
import { Title } from "@components/ui/Title";
import ReviewCard from "../ReviewCard";
import ReviewCardSkeleton from "../ReviewCardSkeleton";

interface Props extends ReviewsQueryVariables {
  title?: string;
  data?: ReviewCardFragment[];
  children?: ReactNode;
  modalStyle?: boolean;
  isLoading?: boolean;
  skeletonLength?: number;
}

const ReviewsBlock: FC<Props> = ({
  title,
  data,
  modalStyle = false,
  isLoading,
  skeletonLength,
  children,
}) => {
  if (data?.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col w-full mb-20">
      {title && (
        <Title as="h2" size="md" divider>
          {title}
        </Title>
      )}
      <div className="flex flex-wrap w-full">
        {data?.map((node) => (
          <ReviewCard key={node.id} modalStyle={modalStyle} {...node} />
        ))}
        {isLoading &&
          Array(skeletonLength)
            .fill("x")
            .map((_, index) => (
              <ReviewCardSkeleton key={index} modalStyle={modalStyle} />
            ))}
      </div>
      {children && (
        <div className="flex w-full justify-center text-center mt-8">
          {children}
        </div>
      )}
    </div>
  );
};

export default ReviewsBlock;
