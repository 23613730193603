import React, { FC, useState } from "react";
import {
  getPaginationNodes,
  getPaginationData,
} from "@helpers/getPaginationNodes";
import { ReviewsQuery, ReviewsQueryVariables } from "@graphql/types";
import { useInfiniteQuery } from "react-query";
import { graphql } from "@helpers/graphql";
import { t } from "@helpers/translate";
import ReviewsBlock from "@components/common/ReviewsBlock";
import LoadMore from "@components/common/Pagination/LoadMore";
import Modal from "@components/ui/Modal";

interface ReviewsProps {
  type: "supplier" | "tours";
  initialData?: ReviewsQuery;
  filters?: ReviewsQueryVariables;
  title?: string;
}

const Reviews: FC<ReviewsProps> = ({ type, initialData, filters, title }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [modalTimesOpen, setModalTimesOpen] = useState(0);

  const hasNextAtLeastTwoPage = initialData?.reviews.pageInfo.hasNextPage;

  const { data, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery(
    [`${type}-reviews`, filters],
    async ({ pageParam }) => {
      const res = await graphql.reviews({
        ...filters,
        first: isFirstFetch ? 20 : 5,
        after: pageParam,
      });

      setIsFirstFetch(false);

      return getPaginationData(res.reviews);
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      enabled: modalTimesOpen > 0,
    }
  );

  return (
    <div>
      <ReviewsBlock
        title={
          title ??
          t({
            id: "tour.block.reviews.title",
            message: "Reviews",
          })
        }
        data={initialData?.reviews.edges.map((e) => e.node) || []}
      >
        <LoadMore
          fetchNextPage={() => {
            setModalIsOpen(true);
            setModalTimesOpen((old) => old + 1);
          }}
          hasNextPage={hasNextAtLeastTwoPage}
          isFetchingNextPage={false}
          text={t({
            id: "pagination.button.show-more",
            message: "Show All Reviews",
          })}
        />
      </ReviewsBlock>

      {modalIsOpen && (
        <Modal
          title="Reviews"
          blockScroll={true}
          onClose={() => {
            setModalIsOpen(false);
          }}
        >
          <div className="flex flex-col">
            <ReviewsBlock
              data={getPaginationNodes(data)}
              modalStyle={true}
              isLoading={isFetching}
              skeletonLength={4}
            >
              <div className="">
                {data && (
                  <LoadMore
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetching}
                    text={t({
                      id: "pagination.button.load-more",
                      message: "Load More",
                    })}
                  />
                )}
              </div>
            </ReviewsBlock>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Reviews;
